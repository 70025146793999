import React,{useState} from 'react';
import DynamicForm from '../../components/dynamicform';
import Footer from '../../components/footer';
import NGLVHeader from '../../components/header';
import { johnbooklet } from '../../../services/gospelofjohnbookletService';
import { toast } from 'react-toastify';

const pdf_url = "https://bftw-resources.s3.ap-south-1.amazonaws.com/Pdf/No+Greater+Love+PDF+Download.pdf"
const DownloadForm = () => {
  const [btndis,setBtndis] = useState(false)
    const fields = [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          required:false
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
          required:true
        },
        {
          name: 'address',
          label: 'Address',
          type: 'textarea',
          required:false
        }
      ];

      const handleSubmit = async(formData) => {
        setBtndis(true)
        // Handle form submission here
        console.log(formData);
        try {
          // const obj = {
          //   name:formData.name,
          //   email:formData.email,
          //   address:formData.address
          // }
          // console.log(obj,'obj');
          console.log(formData,'fdfdfd');
          const bookletdata = await johnbooklet(formData);
          console.log(bookletdata,'bookletdata');
          if (bookletdata) {
          
            toast.success(bookletdata.msg)
            const response = await fetch(pdf_url);
            const blob = await response.blob();
            const fileName = 'No Greater Love PDF Download.pdf';
      
            // Create a temporary URL for the blob data
            const url = window.URL.createObjectURL(blob);
      
            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
      
            // Clean up after the download
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setBtndis(false)
          }
        } catch (ex) {
          toast.success(ex.msg)
          setBtndis(false)
          // alert("Something error");
        }
      };
    return ( 
        <>
            <div>
              <NGLVHeader />
                {/* <header>
                    <h2>Download Form</h2>
                </header> */}
                <section id="intro" class="content-section">
                <div >
              <img src={require('../../../assets/NGLV_images/home_banner.png')} style={{ width: '100%', height: '350px' }} />
            </div>
            <p className='fsize'>Please fill out the form to receive your FREE digital download of The Gospel of John.</p>
            <p className='fsize'>After submitting the form, you will be sent to a new page where you can download a PDF.</p>
             
                <div className='container-fluid content'>
                   <DynamicForm fields={fields} btndisa={btndis} onSubmit={handleSubmit} />
                </div>
                </section>
                </div>
                <Footer />
        </>
     );
}
 
export default DownloadForm;