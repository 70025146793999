import React, { Component } from 'react';
import DynamicForm from '../../components/dynamicform';
import Footer from '../../components/footer';
import NGLVHeader from '../../components/header';



const BookletForm = () => {
    const fields = [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
          required:true
        },
        {
          name: 'email',
          label: 'Email',
          type: 'text',
          required:false
        },
        {
          name: 'address',
          label: 'Address',
          type: 'textarea',
          required:true
        }
      ];

      const handleSubmit = async(formData) => {
        // Handle form submission here
        console.log(formData,'formDtata');
       
      };
    return ( 
        <>
        <div>
          <NGLVHeader />
            {/* <header>
                <h2>Booklet Form</h2>
            </header> */}
            <section id="intro" class="content-section">
            <div >
              <img src={require('../../../assets/NGLV_images/home_banner.png')} style={{ width: '100%', height: '350px' }} />
            </div>
            <p className='fsize'>Please fill out the form to receive your FREE paper booklet of The Gospel of John.</p>
            <p className='fsize'>After submitting the form, we will mail you a copy of the booklet.</p>
            <div className='container-fluid content'>
               <DynamicForm fields={fields} onSubmit={handleSubmit} />
            </div>
            </section>
            </div>
            <Footer />
    </>
     );
}
 
export default BookletForm;