import { axiosInstance, sub_URL } from './httpService';

export const johnbooklet = async (data) => {
    console.log(data,'johnbookletservice');
    try {
        console.log('loginAuth data', data)
        const url = `${sub_URL}${'/nglgospeljohnbooklet/gospeljohnbooklet'}`;
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const survey = async (data) => {

    try {
        console.log('loginAuth data', data)
        const url = `${sub_URL}${'/nglsurvey/survey'}`;
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};